<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          <span
            class="label label-inline label-pill label-info label-rounded mr-2"
          >
            Информация:
          </span>
          Здесь выводятся все ранее созданные тесты. Отредактируйте,
          заархивируйте или активируйте тест заново.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Тесты</h3>
        </div>
      </div>
      <div class="card-body">
        <b-spinner
          variant="primary"
          label="Загрузка"
          v-if="processes.length < 1"
        ></b-spinner>
        <div
          class="card card-custom gutter-b example example-compact"
          :class="!process.archived ? 'border-primary' : 'border-dark'"
          v-for="process in processes"
          :key="process.id"
        >
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <b-badge
                  class="mr-3"
                  :variant="process.archived ? 'dark' : 'primary'"
                  >{{ process.archived ? "В архиве" : "Действующий" }}</b-badge
                >
                <b-badge
                  class="mr-3"
                  :variant="!process.resolveProcess ? 'success' : 'danger'"
                  >{{
                    !process.resolveProcess ? "Автоматически" : "Специалистом"
                  }}</b-badge
                >
                {{ process.processName }}
              </h3>
            </div>
            <!--<div class="card-toolbar">
              <div class="example-tools justify-content-center">
                <span data-toggle="tooltip" class="example-toggle"></span
                ><span data-toggle="tooltip" class="example-copy"></span>
              </div>
            </div>-->
          </div>
          <div class="card-body">
            <p>{{ process.description }}</p>
            <a
              v-if="!process.hasOwnProperty('QR')"
              href="#"
              class="card-link text-primary"
              @click.prevent="getQR(process)"
              >QR</a
            >
            <a
              href="#"
              class="card-link text-success"
              @click.prevent="previewProcess(process)"
              >Просмотр</a
            >
            <a
              href="#"
              class="card-link text-primary"
              @click.prevent="createFromProcess(process)"
              >Создать на основе</a
            >

            <a
              href="#"
              class="card-link text-info"
              @click.prevent="loadAnswers(process)"
              v-if="!process.hasOwnProperty('list')"
              >Посмотреть ответы</a
            >
            <a
              href="#"
              class="card-link text-info"
              @click.prevent="hideAnswers(process)"
              v-else
              >Спрятать ответы</a
            >
            <!--<a
              href="#"
              class="card-link text-info"
              @click.prevent="editProcess(process)"
              >Редактировать</a
            >-->
            <a
              href="#"
              class="card-link text-dark"
              v-if="!process.archived"
              @click.prevent="toggleArchive(process)"
              >Архивировать</a
            >
            <a
              href="#"
              class="card-link text-success"
              v-if="process.archived"
              @click.prevent="toggleArchive(process)"
              >Разархивировать</a
            >
            <div v-if="process.hasOwnProperty('qrconfig')">
              <vue-q-art :config="process.qrconfig"></vue-q-art>
              <p>{{ process.qrconfig.value }}</p>
            </div>
            <div v-if="process.hasOwnProperty('list')">
              <p v-if="process.list.length < 1">Пока нет заполнений</p>
              <div class="timeline timeline-6 mt-3" v-else>
                <template v-for="(item, i) in process.list">
                  <!--begin::Item-->
                  <div
                    class="timeline-item align-items-start"
                    v-bind:key="i"
                    @click="checkAnswers(item)"
                  >
                    <!--begin::Label-->
                    <div
                      class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                    >
                      {{ item.time }}
                    </div>
                    <!--end::Label-->

                    <!--begin::Badge-->
                    <div class="timeline-badge">
                      <i class="icon-xl" v-bind:class="item.badge"></i>
                    </div>
                    <!--end::Badge-->

                    <!--begin::Text-->
                    <div
                      class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
                    >
                      <span
                        v-bind:class="{
                          'font-weight-bolder text-dark-75': item.bold,
                          'mr-4': item.images
                        }"
                        v-html="item.desc"
                      ></span>
                    </div>
                    <!--end::Text-->
                  </div>
                  <!--end::Item-->
                </template>
              </div>
            </div>
          </div>
        </div>

        <!--<b-card-group column>
          <b-card :border-variant="process.archived ? 'primary':'dark'" :title="process.processName" :sub-title="process.description" class="text-center" v-for="process in processes" :key="process.id">
            <b-card-body>
              <a href="#" class="card-link text-info">Редактировать</a>
              <a href="#" class="card-link text-dark" v-if="!process.archived">Архивировать</a>
              <a href="#" class="card-link text-success" v-if="process.archived">Разархивировать</a>
            </b-card-body>
          </b-card>
        </b-card-group>-->
      </div>
    </div>
  </div>
</template>

<script>
import VueQArt from "vue-qart";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {
  LOAD_PROCESS,
  LOAD_TRUE_PROCESS,
  SET_PROCESS_STATUS
} from "@/core/services/store/process.module";

export default {
  name: "process-all",
  components: {
    VueQArt
  },
  data() {
    return {
      processes: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {
    this.loadProcesses();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Все тесты" }]);
  },
  methods: {
    getQR(process) {
      return new Promise(resolve => {
        var vm = this;
        ApiService.post("api/v1/cop/", {
          id: process.id
        }).then(response => {
          //   console.log(response);
          if (typeof response !== "undefined") {
            vm.$set(process, "qrconfig", {
              value: `${this.$host}/#/qr?id=${response.data.id}`,
              imagePath: "media/logos/logo-chekkr-color.png",
              filter: "color"
            });
          }
          resolve(response);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    loadProcesses() {
      return new Promise(resolve => {
        ApiService.get("api/v1/process").then(({ data }) => {
          //  console.log(data.results);
          this.processes = data.results;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    previewProcess(process) {
      // console.log(process);
      this.$store
        .dispatch(LOAD_PROCESS, { id: process.id })
        .then(() => {
          this.$store.commit(SET_PROCESS_STATUS, "preview");
          this.$router.push("/process/from");
        })
        .catch(error => {
          console.error(error);
        });
    },

    createFromProcess(process) {
      //     console.log(process);
      this.$store
        .dispatch(LOAD_PROCESS, { id: process.id })
        .then(() => {
          this.$store.commit(SET_PROCESS_STATUS, "create");
          this.$router.push("/process/from");
        })
        .catch(error => {
          console.error(error);
        });
    },

    editProcess(process) {
      // console.log(process);
      this.$store
        .dispatch(LOAD_TRUE_PROCESS, { id: process.id })
        .then(() => {
          this.$store.commit(SET_PROCESS_STATUS, "edit");
          this.$router.push("/process/new");
        })
        .catch(error => {
          console.error(error);
        });
    },

    toggleArchive(process) {
      return new Promise(resolve => {
        ApiService.patch("api/v1/process/" + process.id + "/", {
          archived: !process.archived
        }).then(({ data }) => {
          process.archived = !process.archived;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    loadAnswers(process) {
      return new Promise(resolve => {
        var vm = this;
        ApiService.post("api/v1/gsp/", {
          id: process.id
        }).then(response => {
          //     console.log(response);
          if (typeof response !== "undefined") {
            vm.$set(process, "list", []);
            if (response.data.length) {
              response.data.forEach(function(v) {
                process.list.push({
                  id: v.id,
                  processId: v.process[0].id,
                  processName: v.process[0]["processName"],
                  user: `${v.user[0]["name"]} ${v.user[0]["lastname"]} (${v.user[0]["email"]})`,
                  time: vm.parseTime(v.dateEnd, "dd-mm-yyyy"),
                  desc: `${v.user[0]["name"]} ${v.user[0]["lastname"]} (${v.user[0]["email"]}) изменил статус ${v.process[0]["processName"]} на ${v.status[0]["statusName"]}`,
                  badge: "fa fa-genderless text-success",
                  bold: true
                });
              });
            }
          }

          resolve(response);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    hideAnswers(process) {
      delete this.$delete(process, "list");
    },

    parseTime(datetime, format = "dd.mm") {
      var newdate = new Date(datetime);
      var dd = String(newdate.getDate()).padStart(2, "0");
      var mm = String(newdate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = newdate.getFullYear();
      //var yy = yyyy.toString().substr(-2);
      //return dd + '.' + mm + '.' + yyyy;
      if (format === "dd.mm") {
        return dd + "." + mm;
      }
      if (format === "dd-mm-yyyy") {
        return dd + "-" + mm + "-" + yyyy;
      }
    },

    checkAnswers(checklist) {
      this.$router.push({
        name: "checklist-answer",
        params: { status: checklist }
      });
    }
  }
};
</script>
